// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kIVGTpQyQCnRh1utHGmS{display:flex;flex-wrap:wrap;align-items:flex-start}.eKgxlcBo7I99WHwpYFw2{margin-bottom:20px}.DzOsnXXl3qcwKPgE0vh3{margin-bottom:20px}.TjIq9u7V7kTbkA7LzEfA{flex-wrap:wrap}@media(max-width: 1048px){.TjIq9u7V7kTbkA7LzEfA{display:block !important}}@media(min-width: 576px){.TjIq9u7V7kTbkA7LzEfA{flex-wrap:nowrap}}.br7WeInmFVEMf1hlX3a9{margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/pages/LicensedAsset/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,cAAA,CACA,sBAAA,CACF,sBACE,kBAAA,CACF,sBACE,kBAAA,CACF,sBACE,cAAA,CCIA,0BDLF,sBAGI,wBAAA,CAAA,CCMF,yBDTF,sBAKI,gBAAA,CAAA,CACJ,sBACE,kBAAA","sourcesContent":["@import \"@sass/base/_breakpoints.sass\"\n\n.columns\n  display: flex\n  flex-wrap: wrap\n  align-items: flex-start\n.colLeft\n  margin-bottom: 20px\n.colCenter\n  margin-bottom: 20px\n.details\n  flex-wrap: wrap\n  @include small-pc\n    display: block !important\n  @include sm\n    flex-wrap: nowrap\n.licensingHistoryContainer\n  margin-bottom: 20px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm\n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columns": `kIVGTpQyQCnRh1utHGmS`,
	"colLeft": `eKgxlcBo7I99WHwpYFw2`,
	"colCenter": `DzOsnXXl3qcwKPgE0vh3`,
	"details": `TjIq9u7V7kTbkA7LzEfA`,
	"licensingHistoryContainer": `br7WeInmFVEMf1hlX3a9`
};
export default ___CSS_LOADER_EXPORT___;
