// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N3Geg2MG4Xtf1xOIKafQ{display:flex;flex-direction:column;justify-content:space-between;margin-bottom:15px;text-align:center;align-items:center}@media(min-width: 768px){.N3Geg2MG4Xtf1xOIKafQ{margin-bottom:0px;text-align:left;align-items:flex-start}}.HcWd7ULlQOerjreCozOR{margin-bottom:10px}.dzRbBO1yCeYkRR_BZVq1{font-size:14px;margin-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/pages/ManageLLMAccess/page/ManageLLMAccess/components/TagCard/component/InfoTag/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CCeC,yBDrBH,sBAQI,iBAAA,CACA,eAAA,CACA,sBAAA,CAAA,CACJ,sBACE,kBAAA,CACF,sBACE,cAAA,CACA,kBAAA","sourcesContent":["@import \"@sass/base/_breakpoints.sass\"\n\n.infoTag\n  display: flex\n  flex-direction: column\n  justify-content: space-between\n  margin-bottom: 15px\n  text-align: center\n  align-items: center\n  @include md\n    margin-bottom: 0px\n    text-align: left\n    align-items: flex-start\n.info\n  margin-bottom: 10px\n.llmTitle\n  font-size: 14px\n  margin-bottom: 10px\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm\n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoTag": `N3Geg2MG4Xtf1xOIKafQ`,
	"info": `HcWd7ULlQOerjreCozOR`,
	"llmTitle": `dzRbBO1yCeYkRR_BZVq1`
};
export default ___CSS_LOADER_EXPORT___;
