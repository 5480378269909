// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t3K8H4VVREgl0nKn4x4w{background:#fff;border:1px solid rgba(172,172,172,0.1411764706);padding:.5rem 1rem;border-radius:2px;height:40px;width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/Models/ModelList/components/ModalAddModel/components/FormModels/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,+CAAA,CACA,kBAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".select\n  background: #fff\n  border: 1px solid rgba(172,172,172,.1411764706)\n  padding: .5rem 1rem\n  border-radius: 2px\n  height: 40px\n  width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `t3K8H4VVREgl0nKn4x4w`
};
export default ___CSS_LOADER_EXPORT___;
