// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HJY1FJLPPnA4yDzX6lCS th,td{border:none !important}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/pages/LicensedAsset/components/RevenueBreakdown/components/RevenueHistoryTable/styles.module.sass"],"names":[],"mappings":"AAAA,4BACE,sBAAA","sourcesContent":[".row th, td\n  border: none !important  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `HJY1FJLPPnA4yDzX6lCS`
};
export default ___CSS_LOADER_EXPORT___;
