// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ETdL3CzPfnPuT1v6sle7{display:flex;justify-content:space-between;align-items:center;padding:10px 24px}.CUBYuSFVX0ujzErLkw8V{display:flex;align-items:center}.IIk6Ie3I6ntogA4nkhtJ{border-radius:0 !important}.IIk6Ie3I6ntogA4nkhtJ.bFGfocxeGlBPpkDmp6ES{height:25px !important;width:110px !important}.h9s6hZpWmqxeeVWDNeAE{text-transform:uppercase;font-weight:bold;line-height:0;margin-right:10px}.ppWNUHv0gjLI8ifdmEyI{font-size:13px;line-height:0}`, "",{"version":3,"sources":["webpack://./src/components/cards/EmbeddingCard/components/Footer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,iBAAA,CACF,sBACE,YAAA,CACA,kBAAA,CACF,sBACE,0BAAA,CACA,2CACE,sBAAA,CACA,sBAAA,CACJ,sBACE,wBAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CACF,sBACE,cAAA,CACA,aAAA","sourcesContent":[".footer\n  display: flex\n  justify-content: space-between\n  align-items: center\n  padding: 10px 24px\n.info\n  display: flex\n  align-items: center\n.button\n  border-radius: 0 !important\n  &.cardAsLink\n    height: 25px !important\n    width: 110px !important\n.fileType\n  text-transform: uppercase\n  font-weight: bold\n  line-height: 0\n  margin-right: 10px\n.id\n  font-size: 13px\n  line-height: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `ETdL3CzPfnPuT1v6sle7`,
	"info": `CUBYuSFVX0ujzErLkw8V`,
	"button": `IIk6Ie3I6ntogA4nkhtJ`,
	"cardAsLink": `bFGfocxeGlBPpkDmp6ES`,
	"fileType": `h9s6hZpWmqxeeVWDNeAE`,
	"id": `ppWNUHv0gjLI8ifdmEyI`
};
export default ___CSS_LOADER_EXPORT___;
