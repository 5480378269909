// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwBb9Y50q_fvsdeAj4wp{position:relative}.F8jOkK9i0WaHfk1MMwr_{width:20px;height:20px;display:inline-block;border:1px solid red}`, "",{"version":3,"sources":["webpack://./src/components/forms/InputCheckBox/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACF,sBACE,UAAA,CACA,WAAA,CACA,oBAAA,CACA,oBAAA","sourcesContent":[".customRadio\n  position: relative\n.radio\n  width: 20px\n  height: 20px\n  display: inline-block\n  border: 1px solid red\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customRadio": `TwBb9Y50q_fvsdeAj4wp`,
	"radio": `F8jOkK9i0WaHfk1MMwr_`
};
export default ___CSS_LOADER_EXPORT___;
