// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n7XLF2dF_MBt4JQtjYDC{display:flex;align-items:center}.n7XLF2dF_MBt4JQtjYDC .z4EKHWn3kCjKRqMGPPLJ{width:50px;margin-right:5px}`, "",{"version":3,"sources":["webpack://./src/pages/ManageLLMAccess/page/ManageLLMAccess/components/ModalRegisterLLM/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,4CACE,UAAA,CACA,gBAAA","sourcesContent":[".inputImage\n  display: flex\n  align-items: center\n  .image\n    width: 50px\n    margin-right: 5px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputImage": `n7XLF2dF_MBt4JQtjYDC`,
	"image": `z4EKHWn3kCjKRqMGPPLJ`
};
export default ___CSS_LOADER_EXPORT___;
