// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fFSXepaHZ0u0Vg5XHr6_{margin-bottom:5px;color:#393942}.l5Aci4F1Ge0XGpIqiNAt.WlhO3XU4qhEVVffja1am{color:#a4a4a4;margin-bottom:0 !important}.WpZFyXwy8FFgvp6fS2l2{list-style-type:disc;padding-left:20px}.jXNI9xKZo1OGPt20iN5g{display:flex;flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/pages/LicensedAsset/components/EmbeddingsCreated/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,aAAA,CACF,2CACE,aAAA,CACA,0BAAA,CACF,sBACE,oBAAA,CACA,iBAAA,CACF,sBACE,YAAA,CACA,cAAA","sourcesContent":[".row\n  margin-bottom: 5px\n  color: #393942\n.label.current\n  color: #a4a4a4\n  margin-bottom: 0 !important\n.list\n  list-style-type: disc\n  padding-left: 20px\n.embeddings\n  display: flex\n  flex-wrap: wrap\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `fFSXepaHZ0u0Vg5XHr6_`,
	"label": `l5Aci4F1Ge0XGpIqiNAt`,
	"current": `WlhO3XU4qhEVVffja1am`,
	"list": `WpZFyXwy8FFgvp6fS2l2`,
	"embeddings": `jXNI9xKZo1OGPt20iN5g`
};
export default ___CSS_LOADER_EXPORT___;
