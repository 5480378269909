// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EQ2tQrtXRxTEOCoSIXhk{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/Models/ModelList/components/TableModels/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".table\n  width: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `EQ2tQrtXRxTEOCoSIXhk`
};
export default ___CSS_LOADER_EXPORT___;
