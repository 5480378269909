// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.y0nR86xgiACP5TRqu51w{max-width:400px;width:100%;margin-left:auto;margin-right:auto}.u7GvtjafzVMvn5U8Hhbg{padding-bottom:15px;margin-top:15px;border-bottom:1px solid #ddd}.o2gNbKeKXm9k2gKMk_5x{font-weight:bold}.u7GvtjafzVMvn5U8Hhbg:first-child{margin-top:0}.u7GvtjafzVMvn5U8Hhbg:last-child{border:none}`, "",{"version":3,"sources":["webpack://./src/pages/Embeddings/pages/Embeddings/components/ListForLicensingModal/components/ItemsList/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CACF,sBACE,mBAAA,CACA,eAAA,CACA,4BAAA,CACF,sBACE,gBAAA,CACF,kCACE,YAAA,CACF,iCACE,WAAA","sourcesContent":[".assetList\n  max-width: 400px\n  width: 100%\n  margin-left: auto\n  margin-right: auto\n.item\n  padding-bottom: 15px\n  margin-top: 15px\n  border-bottom: 1px solid #ddd\n.id\n  font-weight: bold\n.item:first-child\n  margin-top: 0\n.item:last-child\n  border: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetList": `y0nR86xgiACP5TRqu51w`,
	"item": `u7GvtjafzVMvn5U8Hhbg`,
	"id": `o2gNbKeKXm9k2gKMk_5x`
};
export default ___CSS_LOADER_EXPORT___;
