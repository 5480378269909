// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wuwy3rJdRRraL4phq1rS{width:100%}.Fy88qBhVfVlAnFQ0xwei{text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/pages/Embeddings/components/ListForLicensingForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACF,sBACE,wBAAA","sourcesContent":[".input\n  width: 100%\n.chip\n  text-transform: uppercase\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `wuwy3rJdRRraL4phq1rS`,
	"chip": `Fy88qBhVfVlAnFQ0xwei`
};
export default ___CSS_LOADER_EXPORT___;
