// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sdAB8ont9nfeuFBRt9QB{max-width:400px;width:100%;margin-left:auto;margin-right:auto}.MuVkplIQDssJQXE5xPD2{padding-bottom:15px;margin-top:15px;border-bottom:1px solid #ddd}.uLLwj5Hg2rlhZlfColF2{font-weight:bold}.MuVkplIQDssJQXE5xPD2:first-child{margin-top:0}.MuVkplIQDssJQXE5xPD2:last-child{border:none}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/pages/LicensedAssets/components/GenerateAiModal/components/AssetList/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CACF,sBACE,mBAAA,CACA,eAAA,CACA,4BAAA,CACF,sBACE,gBAAA,CACF,kCACE,YAAA,CACF,iCACE,WAAA","sourcesContent":[".assetList\n  max-width: 400px\n  width: 100%\n  margin-left: auto\n  margin-right: auto\n.item\n  padding-bottom: 15px\n  margin-top: 15px\n  border-bottom: 1px solid #ddd\n.id\n  font-weight: bold\n.item:first-child\n  margin-top: 0\n.item:last-child\n  border: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetList": `sdAB8ont9nfeuFBRt9QB`,
	"item": `MuVkplIQDssJQXE5xPD2`,
	"id": `uLLwj5Hg2rlhZlfColF2`
};
export default ___CSS_LOADER_EXPORT___;
