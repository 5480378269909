// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pCow0_xNeQFqKRz89jqr{display:flex}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/components/GenerateEmbeddingForm/components/CheckboxEmbedding/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA","sourcesContent":[".embeddingCheckBox\n  display: flex\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"embeddingCheckBox": `pCow0_xNeQFqKRz89jqr`
};
export default ___CSS_LOADER_EXPORT___;
