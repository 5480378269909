// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nzcTFM1oPCAkGbQ_wQmW{font-size:13px}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/components/GenerateEmbeddingForm/components/EstimatedCosts/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".estimateCost\n  font-size: 13px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"estimateCost": `nzcTFM1oPCAkGbQ_wQmW`
};
export default ___CSS_LOADER_EXPORT___;
