// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gU8w2TeRqPy_GZTcrLow{cursor:pointer}.hT2noIeLkiSs_sufyRDJ{position:relative}.DhvZ2UWKP3rMbqiNQO7c{text-transform:uppercase;color:#fff;text-align:center;min-width:100px;height:40px;position:absolute;z-index:5;top:0;left:0;background:#000;font-size:20px;display:flex;align-items:center;justify-content:center;padding:5px 5px;font-weight:bold}.ZszGK0Rc2BJ19jpO5l_n{position:absolute;z-index:10;top:10px;right:10px}`, "",{"version":3,"sources":["webpack://./src/components/cards/EmbeddingCard/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACF,sBACE,iBAAA,CACF,sBACE,wBAAA,CACA,UAAA,CACA,iBAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CACA,SAAA,CACA,KAAA,CACA,MAAA,CACA,eAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,gBAAA,CACF,sBACE,iBAAA,CACA,UAAA,CACA,QAAA,CACA,UAAA","sourcesContent":[".isLink\n  cursor: pointer\n.card\n  position: relative\n.classification\n  text-transform: uppercase\n  color: #fff\n  text-align: center\n  min-width: 100px\n  height: 40px\n  position: absolute\n  z-index: 5\n  top: 0\n  left: 0\n  background: #000\n  font-size: 20px\n  display: flex\n  align-items: center\n  justify-content: center\n  padding: 5px 5px\n  font-weight: bold\n.radio\n  position: absolute\n  z-index: 10\n  top: 10px\n  right: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isLink": `gU8w2TeRqPy_GZTcrLow`,
	"card": `hT2noIeLkiSs_sufyRDJ`,
	"classification": `DhvZ2UWKP3rMbqiNQO7c`,
	"radio": `ZszGK0Rc2BJ19jpO5l_n`
};
export default ___CSS_LOADER_EXPORT___;
