// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lq7pD_EEEorr2PKZfcLY{width:100%;display:flex;margin-top:8px}._WUQJilchMnU2Sjob5sw{width:6%;heigth:10px;margin-top:7px}.LwbCflmBhBYt9VyOkJDw{width:30%}.trsSMPCrh6GJS_WpQqDI{width:40%}.Hmg2jeUxox3wQY4lMbZp{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/pages/LicensedAsset/components/RevenueBreakdown/components/LicensesChart/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,YAAA,CACA,cAAA,CACF,sBACE,QAAA,CACA,WAAA,CACA,cAAA,CACF,sBACE,SAAA,CACF,sBACE,SAAA,CACF,sBACE,UAAA","sourcesContent":["@import \"@/assets/sass/base/breakpoints\"\n\n.labelsContainer\n  width: 100% \n  display: flex \n  margin-top: 8px\n.iconContainer \n  width: 6%\n  heigth: 10px\n  margin-top: 7px\n.labelContainer\n  width: 30%\n.percentageContainer\n  width: 40%\n.chartContainer\n  width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelsContainer": `Lq7pD_EEEorr2PKZfcLY`,
	"iconContainer": `_WUQJilchMnU2Sjob5sw`,
	"labelContainer": `LwbCflmBhBYt9VyOkJDw`,
	"percentageContainer": `trsSMPCrh6GJS_WpQqDI`,
	"chartContainer": `Hmg2jeUxox3wQY4lMbZp`
};
export default ___CSS_LOADER_EXPORT___;
