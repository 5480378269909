// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zFnnJQ2Cw9JcWbVne3Fd{width:100%}.a4xuPeGxQHqiKIJifwcr{text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/pages/ManageLLMAccess/page/ManageLLMAccessDetails/components/LicensingForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACF,sBACE,wBAAA","sourcesContent":[".input\n  width: 100%\n.chip\n  text-transform: uppercase\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `zFnnJQ2Cw9JcWbVne3Fd`,
	"chip": `a4xuPeGxQHqiKIJifwcr`
};
export default ___CSS_LOADER_EXPORT___;
