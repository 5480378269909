// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M7yv4atZxpOUA1vCRewu.yioDeBsBXMvpp19NJvQO{padding:.375rem .75rem}`, "",{"version":3,"sources":["webpack://./src/components/cards/InfoCard/styles.module.sass"],"names":[],"mappings":"AACA,2CACE,sBAAA","sourcesContent":["@import \"@sass/base/_spacing.sass\"\n.card.current\n  padding: $spacing-xs $spacing-sm\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `M7yv4atZxpOUA1vCRewu`,
	"current": `yioDeBsBXMvpp19NJvQO`
};
export default ___CSS_LOADER_EXPORT___;
