// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aLxVnjrKcYbNIuWAek7I{display:flex}.aLxVnjrKcYbNIuWAek7I:last-child .lgUvuosOCj4fcHJVjwgC{display:none}.Agf_Ki3jk6PMCDvFLAk_{display:flex;flex-direction:column;align-items:center;width:40px;flex-shrink:0}.S4fxGxWo8z_72JCgubUP{flex-shrink:0}.lgUvuosOCj4fcHJVjwgC{display:block;content:"";width:1px;height:100%;border-right:1px dotted var(--color-default-text)}.LZb5UAQzcMtYGyH4WKYx{line-height:1;font-size:13px;flex-grow:1;padding-bottom:10px}.LZb5UAQzcMtYGyH4WKYx>*{margin-bottom:3px}.LZb5UAQzcMtYGyH4WKYx>*:last-child{margin-bottom:10px}.zvsjYjaqoc1EyfqHaawO{color:#8c8c8c}.yMemllLRxIreYruNTyy9{width:50px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;display:inline-block}.RVNj9UNZVvXlhXMxdgYX{display:inline-block}`, "",{"version":3,"sources":["webpack://./src/components/cards/LicensingHistoryCard/components/HistoryItem/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,uDACE,YAAA,CACJ,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,aAAA,CACF,sBACE,aAAA,CACF,sBACE,aAAA,CACA,UAAA,CACA,SAAA,CACA,WAAA,CACA,iDAAA,CACF,sBACE,aAAA,CACA,cAAA,CACA,WAAA,CACA,mBAAA,CACA,wBACE,iBAAA,CACF,mCACE,kBAAA,CACJ,sBACE,aAAA,CACF,sBACE,UAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,oBAAA,CACF,sBACE,oBAAA","sourcesContent":[".historyItem\n  display: flex\n.historyItem:last-child\n  .line\n    display: none\n.iconContainer\n  display: flex\n  flex-direction: column\n  align-items: center\n  width: 40px\n  flex-shrink: 0\n.icon\n  flex-shrink: 0\n.line\n  display: block\n  content: ''\n  width: 1px\n  height: 100%\n  border-right: 1px dotted var(--color-default-text)\n.details\n  line-height: 1\n  font-size: 13px\n  flex-grow: 1\n  padding-bottom: 10px\n  > *\n    margin-bottom: 3px\n  > *:last-child\n    margin-bottom: 10px\n.lightText\n  color:#8c8c8c\n.cutText\n  width: 50px\n  overflow: hidden\n  white-space: nowrap\n  text-overflow: ellipsis\n  display: inline-block\n.userInfo\n  display: inline-block\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"historyItem": `aLxVnjrKcYbNIuWAek7I`,
	"line": `lgUvuosOCj4fcHJVjwgC`,
	"iconContainer": `Agf_Ki3jk6PMCDvFLAk_`,
	"icon": `S4fxGxWo8z_72JCgubUP`,
	"details": `LZb5UAQzcMtYGyH4WKYx`,
	"lightText": `zvsjYjaqoc1EyfqHaawO`,
	"cutText": `yMemllLRxIreYruNTyy9`,
	"userInfo": `RVNj9UNZVvXlhXMxdgYX`
};
export default ___CSS_LOADER_EXPORT___;
