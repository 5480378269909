// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prJccrt5GV4C54ne2AWJ.L7qmLQ1AEd757hqSGT7D{padding:.375rem .75rem}`, "",{"version":3,"sources":["webpack://./src/components/cards/LicensingHistoryCard/styles.module.sass"],"names":[],"mappings":"AACA,2CACE,sBAAA","sourcesContent":["@import \"@sass/base/_spacing.sass\"\n.card.current\n  padding: $spacing-xs $spacing-sm\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `prJccrt5GV4C54ne2AWJ`,
	"current": `L7qmLQ1AEd757hqSGT7D`
};
export default ___CSS_LOADER_EXPORT___;
