// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sWxxEW_yYWemtQaLebWT{margin-bottom:10px}@media(min-width: 768px){.sWxxEW_yYWemtQaLebWT{margin-bottom:0px}}.pA77aMOqkY1iketDZdUU{display:flex;flex-direction:column;margin-bottom:20px}@media(min-width: 768px){.pA77aMOqkY1iketDZdUU{flex-direction:row;justify-content:space-between}}`, "",{"version":3,"sources":["webpack://./src/pages/ManageLLMAccess/page/ManageLLMAccess/components/Title/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CCoBC,yBDrBH,sBAGI,iBAAA,CAAA,CACJ,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CCcC,yBDjBH,sBAKI,kBAAA,CACA,6BAAA,CAAA","sourcesContent":["@import \"@sass/base/_breakpoints.sass\"\n\n.title\n  margin-bottom: 10px\n  @include md\n    margin-bottom: 0px\n.titleContainer\n  display: flex\n  flex-direction: column\n  margin-bottom: 20px\n  @include md\n    flex-direction: row\n    justify-content: space-between\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm\n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `sWxxEW_yYWemtQaLebWT`,
	"titleContainer": `pA77aMOqkY1iketDZdUU`
};
export default ___CSS_LOADER_EXPORT___;
