// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QSMgr6IfYwOvA4nJQJ9h{display:flex;align-items:center;margin-bottom:5px}`, "",{"version":3,"sources":["webpack://./src/pages/LicensedAssets/pages/LicensedAsset/components/SetLlmAccessForm/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":[".checkBox\n  display: flex\n  align-items: center\n  margin-bottom: 5px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBox": `QSMgr6IfYwOvA4nJQJ9h`
};
export default ___CSS_LOADER_EXPORT___;
