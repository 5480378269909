// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VhBojHcu7mroMWS3FHMf,.NeuyQ5RgOpmDCkD1HhNq{display:flex;align-items:center}.VhBojHcu7mroMWS3FHMf{padding:0 15px;position:relative;color:var(--color-main);font-size:14px}.VhBojHcu7mroMWS3FHMf:hover{color:var(--color-main)}.VhBojHcu7mroMWS3FHMf:before{background-color:var(--color-main);border-radius:100%;content:"";display:none;height:7px;left:0;margin-bottom:auto;margin-top:auto;position:absolute;width:7px}.VhBojHcu7mroMWS3FHMf:hover:before{display:block}.VhBojHcu7mroMWS3FHMf.active:before{display:block}`, "",{"version":3,"sources":["webpack://./src/components/menus/NavMenu/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,kBAAA,CACF,sBACE,cAAA,CACA,iBAAA,CACA,uBAAA,CACA,cAAA,CACA,4BACE,uBAAA,CACJ,6BACE,kCAAA,CACA,kBAAA,CACA,UAAA,CACA,YAAA,CACA,UAAA,CACA,MAAA,CACA,kBAAA,CACA,eAAA,CACA,iBAAA,CACA,SAAA,CACF,mCACE,aAAA,CACF,oCACE,aAAA","sourcesContent":[".item,\n.navMenu\n  display: flex\n  align-items: center\n.item\n  padding: 0 15px\n  position: relative\n  color: var(--color-main)\n  font-size: 14px\n  &:hover\n    color: var(--color-main)\n.item:before\n  background-color: var(--color-main)\n  border-radius: 100%\n  content: '',\n  display: none\n  height: 7px\n  left: 0\n  margin-bottom: auto\n  margin-top: auto\n  position: absolute\n  width: 7px\n.item:hover:before\n  display: block\n.item:global.active:before\n  display: block\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `VhBojHcu7mroMWS3FHMf`,
	"navMenu": `NeuyQ5RgOpmDCkD1HhNq`
};
export default ___CSS_LOADER_EXPORT___;
