// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g2poE3fpiyyvOeHY0cBV{width:100%;margin-top:12px;margin-bottom:30px}.g2poE3fpiyyvOeHY0cBV table{background:none !important}.UMge9M7sT_3tHz7dUZ_P th,td{border-top:none !important;border-left:none !important;border-right:none !important}`, "",{"version":3,"sources":["webpack://./src/components/license-history/LicenseHistoryTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,eAAA,CACA,kBAAA,CACA,4BACE,0BAAA,CACJ,4BACE,0BAAA,CACA,2BAAA,CACA,4BAAA","sourcesContent":[".tableContainer\n  width: 100%\n  margin-top: 12px\n  margin-bottom: 30px\n  table\n    background: none !important\n.row th, td\n  border-top: none !important\n  border-left: none !important\n  border-right: none !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `g2poE3fpiyyvOeHY0cBV`,
	"row": `UMge9M7sT_3tHz7dUZ_P`
};
export default ___CSS_LOADER_EXPORT___;
