// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d0FYupQ7V1PTyRjnnwF1{margin-bottom:35px}@media(max-width: 440px){.d0FYupQ7V1PTyRjnnwF1{margin-bottom:20px}}.TPbb3hGPRg21MT9gBRGJ:hover{color:#fff;background:var(--color-secondary-darker) !important}.MzzxMQOWJ3v8vfgl9bME{display:flex;flex-wrap:wrap}@media(max-width: 1048px){.MzzxMQOWJ3v8vfgl9bME{justify-content:center}}.lUjc6IH3R_HlQWAuGXj3{margin-right:24px}@media(max-width: 768px){.lUjc6IH3R_HlQWAuGXj3{margin-right:0}}`, "",{"version":3,"sources":["webpack://./src/pages/Embeddings/pages/Embeddings/components/EmbeddingsList/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CCIC,yBDLH,sBAGI,kBAAA,CAAA,CAEF,4BACE,UAAA,CACA,mDAAA,CACJ,sBACE,YAAA,CACA,cAAA,CCGA,0BDLF,sBAII,sBAAA,CAAA,CACJ,sBACE,iBAAA,CCLA,yBDIF,sBAGI,cAAA,CAAA","sourcesContent":["@import \"@/assets/sass/base/breakpoints\"\n\n.pageContainer\n  margin-bottom: 35px\n  @include small-mobile\n    margin-bottom: 20px\n.registerBtn\n  &:hover\n    color: white\n    background: var(--color-secondary-darker) !important\n.embeddingsCardsContainer\n  display:  flex\n  flex-wrap: wrap\n  @include small-pc\n    justify-content: center\n.marginRight\n  margin-right: 24px\n  @include mobile\n    margin-right: 0\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm\n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `d0FYupQ7V1PTyRjnnwF1`,
	"registerBtn": `TPbb3hGPRg21MT9gBRGJ`,
	"embeddingsCardsContainer": `MzzxMQOWJ3v8vfgl9bME`,
	"marginRight": `lUjc6IH3R_HlQWAuGXj3`
};
export default ___CSS_LOADER_EXPORT___;
