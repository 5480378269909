// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gaNVuyHPoUcvJrmTO7x6{display:flex}.gaNVuyHPoUcvJrmTO7x6 *{font-size:14px;color:var(--color-main)}.FDjfLGmlBhcQ7YM1ujJQ{color:var(--color-main);display:flex}.PBoPhC7Nx3oUjs2nwsAt{font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/components/layout/MainHeader/components/MenuUser/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACF,wBACE,cAAA,CACA,uBAAA,CACF,sBACE,uBAAA,CACA,YAAA,CACF,sBACE,gBAAA","sourcesContent":[".userMenu\n  display: flex\n.userMenu *\n  font-size: 14px\n  color: var(--color-main)\n.menuItem\n  color: var(--color-main)\n  display: flex\n.nickname\n  font-weight: bold"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userMenu": `gaNVuyHPoUcvJrmTO7x6`,
	"menuItem": `FDjfLGmlBhcQ7YM1ujJQ`,
	"nickname": `PBoPhC7Nx3oUjs2nwsAt`
};
export default ___CSS_LOADER_EXPORT___;
