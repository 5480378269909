// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tqlfDPmbGwWhzHoTHjg6{background:var(--color-background-footer);border-top:2px solid var(--color-main)}@media(max-width: 440px){.tqlfDPmbGwWhzHoTHjg6{padding-left:.75rem !important;padding-right:.75rem !important}}.KZVWpOiRPIvnVIxsYxeG{color:var(--color-main);font-size:14px;display:flex;justify-content:space-between;padding:20px 0 40px 0}.yNp1c701GgkekrvKdahZ{width:220px}`, "",{"version":3,"sources":["webpack://./src/components/layout/MainFooter/styles.module.sass","webpack://./src/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAIA,sBACE,yCAAA,CACA,sCAAA,CCCC,yBDHH,sBAII,8BAAA,CACA,+BAAA,CAAA,CACJ,sBACE,uBAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CACA,qBAAA,CACF,sBACE,WAAA","sourcesContent":["@import \"../../../assets/sass/base/_units.sass\"\n@import \"../../../assets/sass/base/_spacing.sass\"\n@import \"../../../assets/sass/base/_breakpoints.sass\"\n\n.footer\n  background: var(--color-background-footer)\n  border-top: 2px solid var(--color-main)\n  @include small-mobile\n    padding-left: $spacing-sm !important\n    padding-right: $spacing-sm !important\n.copyrightBar\n  color: var(--color-main)\n  font-size: 14px\n  display: flex\n  justify-content: space-between\n  padding: 20px 0 40px 0\n.logo\n  width: 220px\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm\n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `tqlfDPmbGwWhzHoTHjg6`,
	"copyrightBar": `KZVWpOiRPIvnVIxsYxeG`,
	"logo": `yNp1c701GgkekrvKdahZ`
};
export default ___CSS_LOADER_EXPORT___;
