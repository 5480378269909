// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OjWZFs3uR9l5gMesyNsc{padding:0 24px;font-size:13px}.J5ka3NISYYPFc70DIZQO{text-transform:capitalize}`, "",{"version":3,"sources":["webpack://./src/components/cards/EbookCard/components/Body/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,cAAA,CACF,sBACE,yBAAA","sourcesContent":[".body\n  padding: 0 24px\n  font-size: 13px\n.author\n  text-transform: capitalize\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `OjWZFs3uR9l5gMesyNsc`,
	"author": `J5ka3NISYYPFc70DIZQO`
};
export default ___CSS_LOADER_EXPORT___;
